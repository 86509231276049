<template>
  <v-row class="pl-5 mt-3 box-color-warning no-gutters">
    <v-col cols="12" sm="1">
      <v-icon color="#fb8c00" class="mr-2">mdi-alert-outline</v-icon>
    </v-col>
    <v-col cols="12" sm="11">
      <span
        >Recuerde que entre los campos
        <b v-if="hasSendMethod">Modo de envío, </b><b>Acción a realizar</b> y
        <b>Autenticación del firmate</b>, solo puede haber uno máximo, que
        utilice OTP o SMS</span
      >
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "RemoteFieldsInfoSms",

  data: () => ({}),
  props: {
    hasSendMethod: {
      type: Boolean,
      default: true
    }
  }
};
</script>
