<template>
  <v-container fluid>
    <BaseBreadcrumb
      class="py-2"
      :title="page.title"
      :icon="mdiClipboardFlowOutline"
    ></BaseBreadcrumb>

    <v-data-table
      :loading="isTableLoading"
      loading-text="Cargando..."
      :server-items-length="itemsTotalCount"
      :options.sync="tableOptions"
      :headers="tableHeaders"
      item-key="id"
      :items="items"
      sort-by="itemDateTime"
      class="elevation-4"
      :sort-desc="true"
      disable-sort
      :footer-props="{
        'items-per-page-options': [5, 10, 25, 50]
      }"
    >
      <template v-slot:top>
        <v-row no-gutters class="pa-2">
          <v-btn
            v-if="isUserAdmin"
            :disabled="isTableLoading"
            color="primary"
            @click="selectFlowTypeDialog = true"
            class="ma-2 btn--expanded"
          >
            <v-icon left>
              {{ mdiPlus }}
            </v-icon>
            Nuevo Flujo
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="isTableLoading"
            outlined
            color="primary"
            @click="getItems()"
            class="ma-2 btn--expanded"
          >
            <v-icon left>
              {{ mdiRefresh }}
            </v-icon>
            Refrescar
          </v-btn>
        </v-row>
      </template>

      <template v-slot:[`item.flowType`]="{ item }">
        <span>{{ item.flowType == 0 ? "Sobre" : "Correspondencia" }}</span>
      </template>

      <template v-slot:[`item.flowTokenId`]="{ item }">
        <div class="id-column-flow tree-dots margin-auto">
          <span>{{ item.flowTokenId }}</span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="ml-2 copy-icon"
                medium
                @click="onClickCopyFlowTokenId(item.flowTokenId)"
              >
                mdi-content-copy
              </v-icon>
            </template>
            <span>Copiar</span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:[`item.itemDateTime`]="{ item }">
        {{ new Date(item.itemDateTime).toLocaleString() }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs" @click="showFlowView(item)">
              <v-icon medium>
                mdi-eye-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Mostrar detalle</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="isUserAdmin"
              icon
              v-bind="attrs"
              v-on="on"
              @click="removeItem(item)"
            >
              <v-icon>
                mdi-trash-can-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Eliminar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="isUserAdmin"
              @click="duplicateFlow(item)"
              icon
              v-on="on"
              v-bind="attrs"
            >
              <v-icon>
                mdi-content-duplicate
              </v-icon>
            </v-btn>
          </template>
          <span>Duplicar</span>
        </v-tooltip>
      </template>

      <template v-if="getHistoricFlowsError" v-slot:no-data>
        <v-btn outlined class="ma-4" color="primary" @click="getItems()">
          Reintentar
        </v-btn>
      </template>

      <template v-slot:footer.prepend>
        <v-row>
          <v-spacer> </v-spacer>
          <v-btn
            icon
            class="mr-4"
            @click="loadFirstsItems"
            :disabled="isTableLoading || tableOptions.page === 1"
          >
            <v-icon>
              {{ mdiChevronDoubleLeft }}
            </v-icon>
          </v-btn>

          <v-btn
            icon
            class="mr-8"
            @click="loadLastsItems"
            :disabled="
              isTableLoading ||
                tableOptions.page * tableOptions.itemsPerPage >= itemsTotalCount
            "
          >
            <v-icon>
              {{ mdiChevronDoubleRight }}
            </v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-table>

    <v-dialog scrollable v-model="dialogDelete" max-width="350">
      <v-card>
        <v-card-title class="headline justify-center mb-4">
          <v-icon size="70" color="primary">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-subtitle
          class="font-weight-bold headline justify-center text-center"
        >
          Confirmar eliminación
        </v-card-subtitle>
        <v-card-text
          class="text-center pb-3 color-grey-text"
          v-html="deleteTextConfirmation()"
        ></v-card-text>
        <v-card-actions class="justify-center pa-4">
          <v-btn color="primary" text @click="dialogDelete = false"
            >Volver</v-btn
          >
          <v-btn
            :loading="isDeleting"
            class="ml-5"
            color="primary"
            @click="deleteItemConfirm()"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="dialogDuplicate" max-width="350">
      <v-card>
        <v-card-title class="headline justify-center mb-4">
          <v-icon size="70" color="primary">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-subtitle
          class="font-weight-bold headline justify-center text-center"
        >
          Confirmar duplicado
        </v-card-subtitle>
        <v-card-text
          class="text-center pb-3 color-grey-text"
          v-html="duplicateTextConfirmation()"
        ></v-card-text>
        <v-card-actions class="justify-center pa-4">
          <v-btn color="primary" text @click="dialogDuplicate = false"
            >Volver</v-btn
          >
          <v-btn
            :loading="isDuplicating"
            class="ml-5"
            color="primary"
            @click="duplicateItemConfirm()"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="selectFlowTypeDialog" width="auto">
      <v-card>
        <v-card-title class="text-h5">Elige el tipo de flujo</v-card-title>

        <v-card-text>
          <v-btn
            class="mt-6"
            block
            color="primary"
            outlined
            @click="
              selectFlowTypeDialog = false;
              showFlowView(null, 0);
            "
            text
          >
            Sobre
          </v-btn>

          <v-btn
            class="mt-4"
            block
            color="primary"
            outlined
            @click="
              selectFlowTypeDialog = false;
              showFlowView(null, 1);
            "
            text
          >
            Combinación de correspondencia
          </v-btn>
        </v-card-text>

        <v-card-actions>
          <v-spacer> </v-spacer>
          <v-btn @click="selectFlowTypeDialog = false" text color="primary">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="flowView" max-width="800">
      <Loading :visible="loadingDetail" :message="'Recuperando detalle...'" />
      <v-form
        v-if="!loadingDetail"
        ref="form"
        class="dialog-custom-form dialog-custom-has-subtitle"
      >
        <v-card class="pa-2">
          <v-card-title class="text-h5">
            {{ computedSelectItem.flowTokenId ? "Detalle" : "Crear" }}
          </v-card-title>

          <v-card-subtitle class="mt-1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  large
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="onEditMailTemplates()"
                  :disabled="updatingSeeDetail"
                >
                  <v-icon>
                    mdi-message-text-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Mensajes</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  large
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="onOpenImageHeader()"
                  :disabled="updatingSeeDetail"
                >
                  <v-icon>
                    mdi-image-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Imágenes</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  large
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="notificationView = true"
                  :disabled="updatingSeeDetail"
                >
                  <v-icon>
                    mdi-email-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Recibe copia</span>
            </v-tooltip>
          </v-card-subtitle>
          <v-card-text>
            <v-divider></v-divider>

            <v-tabs v-model="tab" show-arrows>
              <v-tab no-animation> Inicio </v-tab>
              <v-tab no-animation> Notificaciones </v-tab>
              <v-tab no-animation> Acciones del firmante </v-tab>
              <v-tab no-animation v-if="computedSelectItem.flowType === 1">
                Widget
              </v-tab>
              <v-tabs-items v-model="tab">
                <v-tab-item :transition="false" :reverse-transition="false">
                  <v-container fluid>
                    <v-chip :ripple="false" class="text-subtitle-1 my-2">
                      {{ flowType }}
                    </v-chip>

                    <v-file-input
                      v-model="fileInputWord"
                      show-size
                      :rules="fieldRequerired"
                      small-chips
                      @change="onWordDocumentChanged"
                      v-if="computedSelectItem.flowType == 1"
                      accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      placeholder="Selecciona un documento de word (.docx)*"
                      prepend-icon="mdi-file-word"
                    ></v-file-input>

                    <v-text-field
                      class="mt-4"
                      v-model="computedSelectItem.flowTokenId"
                      v-if="computedSelectItem.flowTokenId"
                      :readonly="true"
                      label="Identificador"
                      append-icon="mdi-content-copy"
                      @click:append="onClickCopyFlowTokenId()"
                      outlined
                      dense
                    ></v-text-field>
                    <v-text-field
                      class="mt-4"
                      v-model="computedSelectItem.name"
                      label="Nombre del flujo*"
                      :rules="fieldRequerired"
                      outlined
                      dense
                    ></v-text-field>
                    <v-select
                      :items="[
                        { value: 0, text: 'Español' },
                        { value: 10, text: 'Inglés' },
                        { value: 20, text: 'Francés' },
                        { value: 30, text: 'Italiano' },
                        { value: 40, text: 'Portugués' }
                      ]"
                      v-model.number="computedSelectItem.language"
                      label="Idioma del flujo"
                      class="mt-2"
                      @change="onChangeLanguage"
                      required
                      outlined
                      dense
                    ></v-select>
                    <v-form ref="form">
                      <v-row>
                        <v-col class="py-0">
                          <v-text-field
                            class="mt-4 col-6"
                            v-model.number="expirationDaysTimeout"
                            label="Vencimiento (días)*"
                            type="number"
                            :rules="fieldExpirationDaysTimeout"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col class="py-0">
                          <v-text-field
                            class="mt-4 col-6"
                            @input="onInputExpirationDays"
                            v-model.number="computedSelectItem.reminderDays"
                            :rules="[
                              fieldReminderDays.maxExpirationDays(
                                computedSelectItem
                              ),
                              fieldReminderDays.alwaysPositive
                            ]"
                            label="Recordatorio (días)"
                            type="number"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-checkbox
                        v-model="computedSelectItem.ltv"
                        label="Firma de larga duración (LTV)"
                      ></v-checkbox>
                      <v-select
                        :items="corporateSignatureItems"
                        v-model="corporateSignature.corporateSignatureType"
                        label="Firma corporativa"
                        required
                        outlined
                        dense
                        class="mt-2"
                      ></v-select>
                      <v-text-field
                        class="mt-n2"
                        maxlength="32"
                        counter
                        v-if="
                          corporateSignature.corporateSignatureType !== 0 &&
                            corporateSignature.corporateSignatureType !==
                              undefined &&
                            corporateSignature.corporateSignatureType !== null
                        "
                        v-model="corporateSignature.corporateSignatureId"
                        label="* Identificador de firma corporativa"
                        outlined
                        dense
                        :rules="signatureIdRule"
                      ></v-text-field>
                    </v-form>
                    <RemoteFieldsInfoSms v-if="invalidFieldsSMS" />
                  </v-container>
                </v-tab-item>
                <v-tab-item :transition="false" :reverse-transition="false">
                  <v-container fluid>
                    <v-select
                      :items="sendMethodItems"
                      v-model.number="computedSelectItem.sendMethod"
                      label="Modo de envío"
                      class="mt-4"
                      required
                      outlined
                      dense
                    ></v-select>
                    <v-text-field
                      class="mt-4"
                      v-model="computedSelectItem.senderName"
                      label="Nombre remitente*"
                      :rules="fieldRequerired"
                      outlined
                      dense
                    ></v-text-field>
                    <v-text-field
                      class="mt-4"
                      v-model="computedSelectItem.senderMail"
                      label="E-mail remitente*"
                      :rules="emailRules"
                      outlined
                      dense
                    ></v-text-field>
                    <v-checkbox
                      v-model="sendDocToRecipient"
                      label="Enviar el documento firmado al participante"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="sendDocToSender"
                      label="Enviar documentos al emisor"
                    ></v-checkbox>
                    <RemoteFieldsInfoSms v-if="invalidFieldsSMS" />
                  </v-container>
                </v-tab-item>
                <v-tab-item :transition="false" :reverse-transition="false">
                  <v-container fluid>
                    <v-select
                      :items="signerActionItems"
                      v-model="computedSelectItem.actionType"
                      label="Acción a realizar"
                      required
                      outlined
                      class="mt-4"
                      dense
                    ></v-select>
                    <v-select
                      :items="authSignerActionItems"
                      v-model="computedSelectItem.authenticationType"
                      label="Autenticación del firmante"
                      required
                      class="mt-4"
                      outlined
                      dense
                    ></v-select>
                    <v-text-field
                      class="mt-4 ml-5 mr-5"
                      v-if="computedSelectItem.authenticationType == 20"
                      v-model="computedSelectItem.accessCode.challenge"
                      label="Pregunta*"
                      :rules="fieldRequerired"
                      outlined
                      dense
                    ></v-text-field>
                    <v-text-field
                      class="mt-4 ml-5 mr-5"
                      v-if="computedSelectItem.authenticationType == 20"
                      v-model="computedSelectItem.accessCode.format"
                      label="Formato"
                      outlined
                      dense
                    ></v-text-field>
                    <RemoteFieldsInfoSms v-if="invalidFieldsSMS" />
                  </v-container>
                </v-tab-item>
                <v-tab-item :transition="false" :reverse-transition="false">
                  <v-container v-if="selectedFile" fluid>
                    <v-btn
                      v-if="!widgetOn"
                      small
                      color="primary"
                      @click="positionateWidget()"
                    >
                      <v-icon left> {{ mdiPlus }} </v-icon>Widget
                    </v-btn>
                    <v-btn
                      v-else
                      small
                      outlined
                      color="red"
                      @click="removeWidget()"
                    >
                      <v-icon left> {{ mdiMinus }} </v-icon>Widget
                    </v-btn>
                  </v-container>
                  <v-container class="tab-container" fluid>
                    <PDFViewer
                      v-if="selectedFile"
                      :file="selectedFile"
                      @page-focus="onUpdateCurrentPage"
                      @page-rendered="onPageRendered"
                      @document-errored="onDocumentErrored"
                    >
                    </PDFViewer>
                    <v-progress-linear
                      v-else-if="converting"
                      indeterminate
                      class="mx-8"
                    ></v-progress-linear>
                    <v-alert
                      v-else
                      text
                      outlined
                      type="info"
                      border="left"
                      class="mt-4"
                    >
                      Selecciona un documento en <b>INICIO</b> para poder añadir
                      un widget.
                    </v-alert>
                  </v-container>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="closeFlowDialog">
              Cerrar
            </v-btn>
            <v-btn
              v-if="isUserAdmin"
              @click="onFinalAction"
              :loading="finalActionLoading"
              :disabled="!validateItem"
              text
              color="primary"
            >
              {{ computedSelectItem.flowTokenId ? "Guardar" : "Crear" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog scrollable v-model="notificationView" persistent>
      <v-form ref="form" class="dialog-custom-form dialog-custom-has-subtitle">
        <v-card>
          <v-card-title class="text-h5">
            Recibe copia
          </v-card-title>

          <v-card-subtitle class="mt-2">
            <v-btn
              outlined
              color="primary"
              text
              :disabled="notifications && notifications.length >= 10"
              @click="notifications.push({ email: '', name: '' })"
            >
              <v-icon left>
                mdi-account-plus
              </v-icon>
              Nuevo participante
            </v-btn>
          </v-card-subtitle>

          <v-card-text>
            <v-data-table
              :headers="headers"
              hide-default-footer
              :items="notifications"
              disable-sort
              v-show="notifications && notifications.length"
            >
              <template v-slot:[`item.name`]="{ item }">
                <v-text-field
                  class="pt-5"
                  v-model="item.name"
                  placeholder="Nombre*"
                  :rules="emptyRules"
                  outlined
                  required
                  dense
                ></v-text-field>
              </template>
              <template v-slot:[`item.email`]="{ item }">
                <v-text-field
                  class="pt-5"
                  v-model="item.email"
                  placeholder="Email*"
                  :rules="notificacionRules"
                  outlined
                  required
                  dense
                ></v-text-field>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      large
                      icon
                      :ripple="false"
                      @click="removeNotification(item.email)"
                    >
                      <v-icon v-bind="attrs" v-on="on">
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar participante</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="
                notifications = computedSelectItem.notifications;
                notificationView = false;
              "
            >
              Cerrar
            </v-btn>

            <v-btn text color="primary" @click="onAplicateNotification">
              Aplicar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog
      scrollable
      v-model="editMailTemplates"
      max-width="1300"
      persistent
    >
      <v-form
        v-model="valid"
        class="dialog-custom-form dialog-custom-has-subtitle"
      >
        <v-card>
          <v-card-title class="text-h5">
            Mensajes
          </v-card-title>

          <v-card-text>
            <v-menu
              top
              right
              :offset-y="true"
              origin="center center"
              transition="scale-transition"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  text
                  v-bind="attrs"
                  v-on="on"
                  @click="onEditMailTemplates()"
                  :disabled="updatingSeeDetail"
                >
                  <v-icon left>
                    mdi-pound
                  </v-icon>
                  Ver Variables
                </v-btn>
              </template>

              <v-list class="pa-2">
                <v-list-item
                  v-for="(item, index) in listVariables"
                  :key="index"
                >
                  <v-list-icon>
                    <v-btn icon large @click="copyText('{' + item.code + '}')"
                      ><v-icon>mdi-content-copy</v-icon></v-btn
                    >
                  </v-list-icon>
                  <v-list-title>
                    <v-chip class="text-body-5">{{
                      "{" + item.code + "}"
                    }}</v-chip>
                  </v-list-title>
                  <v-list-item-content class="ml-2">
                    {{ " *" + item.name }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>

            <div class="row bodyMailTemplate">
              <div class="col-lg-4 col-md-6 col-xs-12" v-if="smsTemplate">
                <v-card-title class="text-h6"
                  >{{ sendMethodSMS ? "Firma" : "OTP" }}
                  <v-tooltip bottom
                    ><template v-slot:activator="{ on, attrs }"
                      ><v-icon
                        v-on="on"
                        v-bind="attrs"
                        class="ml-3 no-xs-display"
                        >mdi-cellphone</v-icon
                      ></template
                    ><span>
                      <span v-if="!sendMethodSMS"
                        >Remitente para el envío de OTP</span
                      >
                      <span v-if="sendMethodSMS"
                        >Plantilla SMS para la solicitud de un proceso de
                        firma.<br />
                        El remitente es usado en el envío OTP. <br />
                        La variable {url} será obligatoria en la
                        descripción.</span
                      >
                    </span>
                  </v-tooltip></v-card-title
                >
                <v-text-field
                  :disabled="editMailTemplatesProgress"
                  dense
                  outlined
                  label="Remitente"
                  :counter="11"
                  :rules="senderSMSRules"
                  class="ml-3 mr-3"
                  placeholder="Introduzca un remitente"
                  v-model="customTemplate.fromSms"
                ></v-text-field>
                <v-textarea
                  v-if="sendMethodSMS"
                  :counter="100"
                  :disabled="editMailTemplatesProgress"
                  :rules="descriptionSMSRules"
                  :no-resize="true"
                  outlined
                  label="Descripción"
                  class="ml-3 mr-3"
                  placeholder="Introduzca la descripción del sms"
                  v-model="customTemplate.signedSms"
                ></v-textarea>
              </div>
              <div class="col-lg-4 col-md-6 col-xs-12" v-if="!sendMethodSMS">
                <v-card-title class="text-h6"
                  >Firma
                  <v-tooltip bottom
                    ><template v-slot:activator="{ on, attrs }"
                      ><v-icon
                        v-on="on"
                        v-bind="attrs"
                        class="ml-3 no-xs-display"
                        >mdi-help-circle-outline</v-icon
                      ></template
                    ><span
                      >Plantilla email para la solicitud de un proceso de
                      firma</span
                    >
                  </v-tooltip></v-card-title
                >
                <v-text-field
                  :disabled="editMailTemplatesProgress"
                  dense
                  outlined
                  label="Asunto"
                  class="ml-3 mr-3"
                  placeholder="Introduzca un asunto"
                  v-model="customTemplate.signEmailSubject"
                ></v-text-field>
                <v-textarea
                  :disabled="editMailTemplatesProgress"
                  :no-resize="true"
                  outlined
                  label="Descripción"
                  class="ml-3 mr-3"
                  placeholder="Introduzca la descripción del email"
                  v-model="customTemplate.signEmailBody"
                ></v-textarea>
              </div>
              <div class="col-lg-4 col-md-6 col-xs-12">
                <v-card-title class="text-h6"
                  >Completado<v-tooltip bottom
                    ><template v-slot:activator="{ on, attrs }"
                      ><v-icon
                        v-on="on"
                        v-bind="attrs"
                        class="ml-3 no-xs-display"
                        >mdi-help-circle-outline</v-icon
                      ></template
                    ><span
                      >Plantilla email para la finalización de un proceso de
                      firma</span
                    >
                  </v-tooltip></v-card-title
                >
                <v-text-field
                  :disabled="editMailTemplatesProgress"
                  dense
                  outlined
                  label="Asunto"
                  class="ml-3 mr-3"
                  placeholder="Introduzca un asunto"
                  v-model="customTemplate.completedEmailSubject"
                ></v-text-field>
                <v-textarea
                  :disabled="editMailTemplatesProgress"
                  :no-resize="true"
                  outlined
                  label="Descripción"
                  class="ml-3 mr-3"
                  placeholder="Introduzca la descripción del email"
                  v-model="customTemplate.completedEmailBody"
                ></v-textarea>
              </div>
              <div class="col-lg-4 col-md-6 col-xs-12">
                <v-card-title class="text-h6"
                  >Rechazado<v-tooltip bottom
                    ><template v-slot:activator="{ on, attrs }"
                      ><v-icon
                        v-on="on"
                        v-bind="attrs"
                        class="ml-3 no-xs-display"
                        >mdi-help-circle-outline</v-icon
                      ></template
                    ><span
                      >Plantilla email para el rechazo de un proceso de
                      firma</span
                    >
                  </v-tooltip></v-card-title
                >
                <v-text-field
                  :disabled="editMailTemplatesProgress"
                  dense
                  outlined
                  label="Asunto"
                  class="ml-3 mr-3"
                  placeholder="Introduzca un asunto"
                  v-model="customTemplate.rejectedEmailSubject"
                ></v-text-field>
                <v-textarea
                  :disabled="editMailTemplatesProgress"
                  :no-resize="true"
                  outlined
                  label="Descripción"
                  class="ml-3 mr-3"
                  placeholder="Introduzca la descripción del email"
                  v-model="customTemplate.rejectedEmailBody"
                ></v-textarea>
              </div>
              <div class="col-lg-4 col-md-6 col-xs-12">
                <v-card-title class="text-h6"
                  >Recordatorio<v-tooltip bottom
                    ><template v-slot:activator="{ on, attrs }"
                      ><v-icon
                        v-on="on"
                        v-bind="attrs"
                        class="ml-3 no-xs-display"
                        >mdi-help-circle-outline</v-icon
                      ></template
                    ><span
                      >Plantilla email para el recordatorio de un proceso de
                      firma</span
                    >
                  </v-tooltip></v-card-title
                >
                <v-text-field
                  :disabled="editMailTemplatesProgress"
                  dense
                  outlined
                  label="Asunto"
                  class="ml-3 mr-3"
                  placeholder="Introduzca un asunto"
                  v-model="customTemplate.rememberEmailSubject"
                ></v-text-field>
                <v-textarea
                  :disabled="editMailTemplatesProgress"
                  :no-resize="true"
                  outlined
                  label="Descripción"
                  class="ml-3 mr-3"
                  placeholder="Introduzca la descripción del email"
                  v-model="customTemplate.rememberEmailBody"
                ></v-textarea>
              </div>
              <div class="col-lg-4 col-md-6 col-xs-12">
                <v-card-title class="text-h6"
                  >Notificación<v-tooltip bottom
                    ><template v-slot:activator="{ on, attrs }"
                      ><v-icon
                        v-on="on"
                        v-bind="attrs"
                        class="ml-3 no-xs-display"
                        >mdi-help-circle-outline</v-icon
                      ></template
                    ><span
                      >Plantilla email para la notificación de un proceso de
                      firma</span
                    >
                  </v-tooltip></v-card-title
                >
                <v-text-field
                  :disabled="editMailTemplatesProgress"
                  dense
                  outlined
                  label="Asunto"
                  class="ml-3 mr-3"
                  placeholder="Introduzca un asunto"
                  v-model="customTemplate.notificationEmailSubject"
                ></v-text-field>
                <v-textarea
                  :disabled="editMailTemplatesProgress"
                  :no-resize="true"
                  outlined
                  label="Descripción"
                  class="ml-3 mr-3"
                  placeholder="Introduzca la descripción del email"
                  v-model="customTemplate.notificationEmailBody"
                ></v-textarea>
              </div>
              <div class="col-lg-4 col-md-6 col-xs-12">
                <v-card-title class="text-h6"
                  >Cancelado<v-tooltip bottom
                    ><template v-slot:activator="{ on, attrs }"
                      ><v-icon
                        v-on="on"
                        v-bind="attrs"
                        class="ml-3 no-xs-display"
                        >mdi-help-circle-outline</v-icon
                      ></template
                    ><span
                      >Plantilla email para la cancelación de un proceso de
                      firma</span
                    >
                  </v-tooltip></v-card-title
                >
                <v-text-field
                  :disabled="editMailTemplatesProgress"
                  dense
                  outlined
                  label="Asunto"
                  class="ml-3 mr-3"
                  placeholder="Introduzca un asunto"
                  v-model="customTemplate.canceledEmailSubject"
                ></v-text-field>
                <v-textarea
                  :disabled="editMailTemplatesProgress"
                  :no-resize="true"
                  outlined
                  label="Descripción"
                  class="ml-3 mr-3"
                  placeholder="Introduzca la descripción del email"
                  v-model="customTemplate.canceledEmailBody"
                ></v-textarea>
              </div>
              <div class="col-lg-4 col-md-6 col-xs-12">
                <v-card-title class="text-h6"
                  >Expirado<v-tooltip bottom
                    ><template v-slot:activator="{ on, attrs }"
                      ><v-icon
                        v-on="on"
                        v-bind="attrs"
                        class="ml-3 no-xs-display"
                        >mdi-help-circle-outline</v-icon
                      ></template
                    ><span
                      >Plantilla email para la expiración de un proceso de
                      firma</span
                    >
                  </v-tooltip></v-card-title
                >
                <v-text-field
                  :disabled="editMailTemplatesProgress"
                  dense
                  outlined
                  label="Asunto"
                  class="ml-3 mr-3"
                  placeholder="Introduzca un asunto"
                  v-model="customTemplate.expiredEmailSubject"
                ></v-text-field>
                <v-textarea
                  :disabled="editMailTemplatesProgress"
                  :no-resize="true"
                  outlined
                  label="Descripción"
                  class="ml-3 mr-3"
                  placeholder="Introduzca la descripción del email"
                  v-model="customTemplate.expiredEmailBody"
                ></v-textarea>
              </div>
              <div class="col-lg-4 col-md-6 col-xs-12">
                <v-card-title class="text-h6"
                  >Pie de página<v-tooltip bottom
                    ><template v-slot:activator="{ on, attrs }"
                      ><v-icon
                        v-on="on"
                        v-bind="attrs"
                        class="ml-3 no-xs-display"
                        >mdi-help-circle-outline</v-icon
                      ></template
                    ><span>Texto para el pie de página de todos los email</span>
                  </v-tooltip></v-card-title
                >
                <v-textarea
                  :disabled="editMailTemplatesProgress"
                  :no-resize="true"
                  outlined
                  class="ml-3 mr-3"
                  placeholder="Introduzca el pie de página de las plantillas email"
                  v-model="customTemplate.footerEmail"
                ></v-textarea>
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn text color="primary" @click="editMailTemplates = false">
              Cerrar
            </v-btn>

            <v-btn
              text
              color="primary"
              :disabled="!valid"
              @click="onAplicateMailTemplate"
            >
              Aplicar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog scrollable v-model="editCompanyLogo" max-width="800" persistent>
      <v-card>
        <v-card-title class="text-h5">
          Imágenes
        </v-card-title>

        <v-card-text>
          <h4 class="mt-2">Cabecera de email</h4>

          <v-file-input
            ref="refFileInputCustomLogo"
            @change="onChangeImg"
            v-model="customLogo"
            :rules="rules"
            accept="image/jpeg, image/jpg"
            placeholder="Selecciona una imagen de cabecera"
            prepend-icon="mdi-camera"
          ></v-file-input>
          <v-img
            ref="refImgCustomLogo"
            :src="urlCustomLogo"
            :aspect-ratio="7"
            contain
          ></v-img>

          <v-divider class="my-4"></v-divider>

          <h4>Pie de página de email</h4>

          <v-file-input
            ref="refFileInputCustomFooterLogo"
            @change="onChangeFooterImg"
            v-model="customFooterLogo"
            :rules="rules"
            accept="image/jpeg, image/jpg"
            placeholder="Selecciona una imagen de pie de página"
            prepend-icon="mdi-camera"
          ></v-file-input>
          <v-img
            ref="refImgCustomFooterLogo"
            :src="urlCustomFooterLogo"
            :aspect-ratio="7"
            contain
          ></v-img>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="editCompanyLogo = false">
            Cerrar
          </v-btn>
          <v-btn color="primary" text @click="onAplicateLogos">Aplicar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Constants from "./ConstantsPSC";
import PDFViewer from "../../../components/pdf/PDFViewer.vue";
import { InitFabricObjects } from "../../../utils/utils";
import { bus } from "../../../main";

import {
  mdiStateMachine,
  mdiClipboardFlowOutline,
  mdiPlus,
  mdiRefresh,
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
  mdiMinus
} from "@mdi/js";
import {
  AssignByAnotherObj,
  AssignObj,
  DataURLtoFile,
  FileToDataUrl,
  FileToB64,
  ScaleDataURL
} from "../../../utils/utils";
import { DefaultMailTemplateData } from "../../../utils/constants";
import Loading from "@/components/commons/Loading";
import RemoteFieldsInfoSms from "@/views/signatures/remote/RemoteFieldsInfoSms.vue";

export default {
  name: "RemoteFlows",
  data: () => ({
    widget: {
      recipientEmail: null,
      recipientName: null,
      documentId: 0,
      height: 0,
      width: 0,
      scaleX: 1,
      scaleY: 1,
      x: 0,
      y: 0,
      page: 1
    },
    widgetOn: false,
    fileInputWord: null,
    converting: false,
    page: {
      title: " Flujos"
    },
    tableOptions: {},
    DefaultMailTemplateData: DefaultMailTemplateData,
    listVariables: [
      { name: "Nombre asociado al sobre", code: "DocumentSetName" },
      { name: "Nombre del emisor", code: "SenderName" },
      { name: "Email del emisor", code: "SenderMail" },
      { name: "Nombre del destinatario", code: "RecipientName" },
      { name: "Email del destinatario", code: "RecipientEmail" },
      { name: "Teléfono del destinatario", code: "RecipientPhoneNumber" },
      { name: "Fecha de creación del sobre", code: "CreationDate" },
      { name: "Fecha de expiración del sobre", code: "ExpirationDate" },
      { name: "Fecha actual", code: "CurrentDate" },
      { name: "Enlace de firma (para método de envío SMS)", code: "url" }
    ],
    tab: null,
    rules: [
      value =>
        !value ||
        ((value.size < 500000 ||
          "La imagen de cabecera debe de ser menos a 0.5 MB!") &&
          (["image/jpeg", "image/jpg"].includes(value && value.type) ||
            "Solo se acepa una imagen de tipo .jpg"))
    ],
    headers: [
      { text: "NOMBRE", value: "name" },
      { text: "EMAIL", value: "email" },
      {
        text: "ACCIONES",
        value: "actions"
      }
    ],
    corporateSignature: {
      corporateSignatureType: 0,
      corporateSignatureId: null
    },
    valid: null,
    dialogDelete: false,
    isDeleting: false,
    loadingDetail: false,
    selectedItemDelete: null,
    dialogDuplicate: false,
    isDuplicating: false,
    selectedFile: null,
    selectedItemDuplicate: null,
    applyCompanyLogoButtonDisabled: true,
    applyCompanyLogoLoading: false,
    editCompanyLogo: false,
    notifications: [],
    customTemplate: DefaultMailTemplateData(),
    b64LogoContent: null,
    b64LogoFooterContent: null,
    customLogo: null,
    customFooterLogo: null,
    urlCustomLogo: null,
    urlCustomFooterLogo: null,
    applyMailTemplatesButtonDisabled: true,
    editMailTemplates: false,
    editMailTemplatesProgress: false,
    applyMailTemplatesLoading: false,
    messageSuccessChangeMailTemplates:
      "Las plantillas de email se han actualizado correctamente",
    messageErrorChangeMailTemplates:
      "No se han podido actualizar las plantillas de email",
    finalActionLoading: false,
    emptyRules: [v => !!v || "El campo es requerido"],
    notificacionRules: [
      v => !!v || "El campo es requerido",
      v =>
        /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z0-9.-]+/.test(v) ||
        "El formato email no es correcto"
    ],
    senderSMSRules: [v => v.length <= 11 || "El tamaño máximo es 11"],
    descriptionSMSRules: [
      v => v.includes("{url}") || "La variable {url} es obligatoria",
      v => v.length <= 100 || "El tamaño máximo es 100"
    ],
    tableHeaders: [
      { text: "NOMBRE", value: "name" },
      {
        text: "ID",
        value: "flowTokenId"
      },
      { text: "TIPO", value: "flowType" },
      {
        text: "FECHA",
        value: "itemDateTime"
      },
      {
        text: "ACCIONES",
        value: "actions"
      }
    ],

    mdiStateMachine: mdiStateMachine,
    mdiClipboardFlowOutline: mdiClipboardFlowOutline,
    mdiPlus: mdiPlus,
    mdiRefresh: mdiRefresh,
    mdiChevronDoubleRight: mdiChevronDoubleRight,
    mdiChevronDoubleLeft: mdiChevronDoubleLeft,
    mdiMinus: mdiMinus,
    itemFilteringDates: [],

    filteringDatesMenu: false,

    itemFilteringStatus: [],
    itemFilteringReference: null,
    itemSearchById: null,

    selectedItem: null,
    updatingSeeDetail: false,
    updatingSeeEventsDetail: false,
    documentSetDetails: undefined,
    documentSetError: null,
    getHistoricFlowsError: false,
    documentSetAuditTrail: undefined,
    currentDocumentPage: 1,
    isDocumentRendered: false,

    defaultsValues: null,
    lastDefaultLanguage: null,

    isTableLoading: false,
    flows: [],

    flowView: false,
    selectFlowTypeDialog: false,
    notificationView: false,
    corporateSignatureItems: Constants.PSC_CORPORATE_SIGNATURE_ITEMS,
    emailRules: [
      v => !!v || "El campo es requerido",
      v =>
        !v ||
        /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z0-9.-]+/.test(v) ||
        "E-mail debe ser válido"
    ],
    signatureIdRule: [
      v => !!v || "El campo es requerido",
      v => !v || v.length === 32 || "El identificador debe tener 32 caracteres"
    ],

    fieldRequerired: [v => !!v || "El campo es requerido"],
    fieldExpirationDaysTimeout: [
      v => !!v || v === 0 || "El campo es requerido",
      v =>
        v === String() ||
        v === void 0 ||
        v === null ||
        (v > 0 && v < 2147483647) ||
        "Debe de tener un valor entre 1 y 2147483647",
      v =>
        v === String() ||
        v === void 0 ||
        v === null ||
        v >= 0 ||
        "No puede ser negativo"
    ],
    fieldReminderDays: {
      maxExpirationDays: computedSelectItem => {
        return () =>
          computedSelectItem.reminderDays === String() ||
          computedSelectItem.reminderDays === null ||
          computedSelectItem.reminderDays === void 0 ||
          computedSelectItem.reminderDays <
            computedSelectItem.expirationDaysTimeout ||
          "No puede ser mayor o igual a los días de vencimiento";
      },
      alwaysPositive: v =>
        v === String() ||
        v === null ||
        v === void 0 ||
        v >= 0 ||
        "No puede ser negativo"
    }
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },

    tableOptions: {
      deep: true,
      handler() {
        this.getItems();
      }
    }
  },

  components: {
    Loading,
    RemoteFieldsInfoSms,
    PDFViewer
  },

  mounted() {
    this.applyMailTemplatesButtonDisabled = true;

    this.flows =
      this.$store.state.flows &&
      this.$store.state.flows.map(item => {
        if (!item.documentSetName)
          item.documentSetName = "Envío de documento sin nombre";
        return item;
      });
  },

  computed: {
    isUserAdmin() {
      return this.$store.getters["user/isLoggedUserAdmin"];
    },
    signerActionItems() {
      const commonOptions = [
        { value: 21, text: "Aceptación simple" },
        { value: 30, text: "Firma manuscrita" },
        { value: 60, text: "Certificado de firma ( sólo para PC )" }
      ];

      const additionalOptions = [
        { value: 40, text: "Firma con OTP" },
        { value: 50, text: "Firma manuscrita + OTP" },
        { value: 51, text: "Firma manuscrita + WhatsApp" }
      ];

      const {
        flowType,
        sendMethod,
        authenticationType
      } = this.computedSelectItem;

      const isSpecialCondition =
        sendMethod === 2 || sendMethod === 3 || authenticationType === 40;
      const filteredCommonOptions =
        sendMethod === 2 || sendMethod === 3
          ? commonOptions.filter(option => option.value !== 60)
          : commonOptions;
      if (flowType === 1) {
        return filteredCommonOptions.concat(
          isSpecialCondition ? [] : additionalOptions
        );
      } else {
        return filteredCommonOptions.concat(
          isSpecialCondition ? [] : additionalOptions
        );
      }
    },

    authSignerActionItems() {
      const commonOptions = [
        { value: 0, text: "Sin autenticación previa" },
        { value: 10, text: "Aceptación de datos personales" }
      ];

      if (this.computedSelectItem.flowType !== 1) {
        commonOptions.push(
          { value: 20, text: "Clave de acceso" },
          { value: 30, text: "Autenticación MRZ" }
        );
      }
      if (
        this.computedSelectItem.sendMethod !== 2 &&
        this.computedSelectItem.sendMethod !== 3 &&
        this.computedSelectItem.actionType !== 40 &&
        this.computedSelectItem.actionType !== 50 &&
        this.computedSelectItem.actionType !== 51
      ) {
        commonOptions.push({ value: 40, text: "Envío de SMS" });
      }
      return commonOptions;
    },
    sendMethodItems() {
      if (
        this.computedSelectItem.authenticationType === 40 ||
        this.computedSelectItem.actionType === 40 ||
        this.computedSelectItem.actionType === 50 ||
        this.computedSelectItem.actionType === 51 ||
        this.computedSelectItem.actionType === 60
      ) {
        return [{ value: 1, text: "eMail" }];
      } else {
        return [
          { value: 1, text: "eMail" },
          { value: 2, text: "SMS" },
          { value: 3, text: "WhatsApp" }
        ];
      }
    },

    flowType() {
      return this.computedSelectItem.flowType == 1
        ? "Tipo: Combinación de correspondencia"
        : "Tipo: Sobre";
    },

    invalidFieldsSMS() {
      const sendMethodHasSMS = this.computedSelectItem.sendMethod === 2,
        authenticationTypeHasSMS =
          this.computedSelectItem.authenticationType === 40,
        actionTypeHasSMS =
          this.computedSelectItem.actionType === 40 ||
          this.computedSelectItem.actionType === 50;
      return (
        (sendMethodHasSMS && (authenticationTypeHasSMS || actionTypeHasSMS)) ||
        (authenticationTypeHasSMS && actionTypeHasSMS)
      );
    },
    validateLogos() {
      return (
        (!this.customLogo ||
          (this.customLogo.size < 500000 &&
            ["image/jpeg", "image/jpg"].includes(
              this.customLogo && this.customLogo.type
            ))) &&
        (!this.customFooterLogo ||
          (this.customFooterLogo.size < 500000 &&
            ["image/jpeg", "image/jpg"].includes(
              this.customFooterLogo && this.customFooterLogo.type
            )))
      );
    },
    smsTemplate() {
      return (
        this.computedSelectItem.authenticationType === 40 ||
        this.computedSelectItem.actionType === 40 ||
        this.computedSelectItem.actionType === 50 ||
        this.sendMethodSMS
      );
    },
    sendMethodSMS() {
      return this.computedSelectItem.sendMethod === 2;
    },
    resources() {
      return this.$store.state.resources || {};
    },
    validateItem() {
      let validation =
        !!this.computedSelectItem.name &&
        !!this.computedSelectItem.senderName &&
        !!this.computedSelectItem.senderMail &&
        /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z0-9.-]+/.test(
          this.computedSelectItem.senderMail
        ) &&
        !!this.expirationDaysTimeout &&
        this.expirationDaysTimeout > 0 &&
        this.expirationDaysTimeout < 2147483647 &&
        ((this.computedSelectItem.reminderDays &&
          this.computedSelectItem.reminderDays >= 0 &&
          this.computedSelectItem.reminderDays <
            this.computedSelectItem.expirationDaysTimeout) ||
          !this.computedSelectItem.reminderDays);
      if (
        this.computedSelectItem.authenticationType === 20 &&
        !this.computedSelectItem.accessCode.challenge
      )
        validation = false;
      if (this.invalidFieldsSMS) validation = false;
      if (
        this.corporateSignature.corporateSignatureType !== 0 &&
        this.corporateSignature.corporateSignatureType !== undefined &&
        !this.corporateSignature.corporateSignatureId
      ) {
        validation = false;
      }
      if (
        this.corporateSignature.corporateSignatureType !== 0 &&
        this.corporateSignature.corporateSignatureId
      ) {
        if (this.corporateSignature.corporateSignatureId.length !== 32)
          validation = false;
      }
      return validation;
    },

    sendDocToRecipient: {
      get() {
        return !!this.computedSelectItem.sendDocToRecipient;
      },
      set(value) {
        this.computedSelectItem.sendDocToRecipient = !!value;
      }
    },
    expirationDaysTimeout: {
      get() {
        return this.computedSelectItem.expirationDaysTimeout;
      },
      set(value) {
        this.computedSelectItem.expirationDaysTimeout = value;
        this.$refs.form.validate();
      }
    },
    sendDocToSender: {
      get() {
        return !!this.computedSelectItem.sendDocToSender;
      },
      set(value) {
        this.computedSelectItem.sendDocToSender = !!value;
      }
    },
    computedSelectItem: {
      get() {
        if (!this.selectedItem) this.resetSelectedItem(true);
        return this.selectedItem;
      },
      set(value) {
        this.selectedItem = value;
      }
    },

    items() {
      return (
        this.$store.state.flows &&
        this.$store.state.flows.map(item => {
          if (!item.documentSetName)
            item.documentSetName = "Envío de documento sin nombre";
          return item;
        })
      );
    },

    itemsTotalCount() {
      return this.$store.getters.historicCountFlowItems;
    }
  },
  created() {
    InitFabricObjects();
    bus.$on("recipient-widget-modified", payload => {
      this.widget.x = payload.x;
      this.widget.y = payload.y;
      this.widget.scaleX = payload.scaleX;
      this.widget.scaleY = payload.scaleY;
      this.widget.height = payload.height;
      this.widget.width = payload.width;
    });
    bus.$on("recipient-widget-removed", () => {
      this.widgetOn = false;
      this.widget = {
        recipientEmail: null,
        recipientName: null,
        documentId: 0,
        height: 0,
        width: 0,
        scaleX: 1,
        scaleY: 1,
        x: 0,
        y: 0,
        page: 1
      };
    });
  },
  methods: {
    async onWordDocumentChanged(file) {
      this.converting = true;
      this.selectedFile = null;
      if (file) {
        const hasExtension = fileName => {
          const parts = fileName.split(".");
          return (
            parts.length > 1 &&
            parts[0] !== "" &&
            parts[parts.length - 1] !== ""
          );
        };
        let fileNameWithoutExtension = file.name;
        if (hasExtension(file.name)) {
          fileNameWithoutExtension = file.name
            .split(".")
            .slice(0, -1)
            .join(".");
        }
        let fileB64 = await FileToB64(file);
        this.computedSelectItem.dataFile = {
          name: fileNameWithoutExtension,
          b64Content: fileB64
        };
        this.$store
          .dispatch("convertWordToPdf", {
            file: file
          })
          .then(res => {
            let blob = new Blob([res.data], { type: "application/pdf" });
            let pdfFile = new File([blob], "archivo.pdf", {
              type: "application/pdf"
            });
            this.selectedFile = pdfFile;
            this.converting = false;
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        this.computedSelectItem.dataFile = null;
        this.converting = false;
      }
    },

    onAplicateLogos() {
      this.editCompanyLogo = false;
      this.computedSelectItem.template.b64LogoContent =
        this.customLogo && this.b64LogoContent;
      this.computedSelectItem.template.b64LogoFooterContent =
        this.customFooterLogo && this.b64LogoFooterContent;
    },
    onInputExpirationDays() {
      if (this.computedSelectItem.reminderDays === String()) {
        this.computedSelectItem.reminderDays = void 0;
      }
    },
    deleteTextConfirmation() {
      if (this.selectedItemDelete != null) {
        return (
          "¿Desea confirmar la eliminación del flujo <strong>" +
          this.selectedItemDelete.name +
          "</strong>?"
        );
      }
      return "";
    },
    duplicateTextConfirmation() {
      if (this.selectedItemDuplicate != null) {
        return (
          "¿Desea confirmar el duplicado del documento <strong>" +
          this.selectedItemDuplicate.name +
          "</strong>?"
        );
      }
      return "";
    },

    defaultTemplateComputed() {
      return (
        (this.resources && this.resources[this.computedSelectItem.language]) ||
        DefaultMailTemplateData()
      );
    },
    changeLanguageDefault() {
      AssignByAnotherObj(
        this.defaultTemplateComputed(),
        this.computedSelectItem.template,
        this.resources[this.lastDefaultLanguage]
      );
    },
    onChangeLanguage(language, change = true) {
      if (!this.resources[language]) {
        this.$store.dispatch("getResources", language).then(() => {
          if (change) this.changeLanguageDefault(language);
          this.lastDefaultLanguage = language;
        });
      } else if (change) {
        this.changeLanguageDefault(language);
        this.lastDefaultLanguage = language;
      }
    },
    onAplicateMailTemplate() {
      this.editMailTemplates = false;
      const logo = this.computedSelectItem.template.b64LogoContent;
      const logoFooter = this.computedSelectItem.template.b64LogoFooterContent;
      this.computedSelectItem.template = { ...this.customTemplate };
      this.computedSelectItem.template.b64LogoContent = logo;
      this.computedSelectItem.template.b64LogoFooterContent = logoFooter;
    },
    onOpenImageHeader() {
      this.editCompanyLogo = true;
      this.b64LogoContent = this.computedSelectItem.template.b64LogoContent;
      this.b64LogoFooterContent = this.computedSelectItem.template.b64LogoFooterContent;
      this.customLogo = null;
      this.customFooterLogo = null;
      if (this.b64LogoContent) {
        ScaleDataURL("data:image/jpg;base64," + this.b64LogoContent, 7).then(
          customData => {
            this.customLogo = DataURLtoFile(customData, "");
            this.previewImage();
          }
        );
      }
      if (this.b64LogoFooterContent) {
        ScaleDataURL(
          "data:image/jpg;base64," + this.b64LogoFooterContent,
          7
        ).then(customData => {
          this.customFooterLogo = DataURLtoFile(customData, "");
          this.previewFooterImage();
        });
      }
    },
    onAplicateNotification() {
      this.notificationView = false;
      const outNotification = this.notifications.filter(notification => {
        return (
          !!notification.email &&
          !!notification.name &&
          /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z0-9.-]+/.test(
            notification.email
          )
        );
      });
      this.computedSelectItem.notifications = new Array(...outNotification);
      this.notifications = new Array(...outNotification);
    },
    copyText(text) {
      navigator.clipboard.writeText(text);
      this.$toasted.global.toast_success({
        message: "Se ha copiado la variable al portapapeles"
      });
    },
    saveCompanyLogo() {
      FileToDataUrl(this.customLogo)
        .then(data => {
          if (data && data.split && data.split(",")[1]) {
            ScaleDataURL("data:image/jpg;base64," + data.split(",")[1], 7).then(
              base64Info => {
                if (
                  base64Info &&
                  base64Info.split &&
                  base64Info.split(",")[1]
                ) {
                  this.b64LogoContent = base64Info.split(",")[1];
                }
              }
            );
          }
        })
        .catch(() => {
          this.applyCompanyLogoLoading = false;
        });
    },
    saveCompanyFooterLogo() {
      FileToDataUrl(this.customFooterLogo)
        .then(data => {
          if (data && data.split && data.split(",")[1]) {
            ScaleDataURL("data:image/jpg;base64," + data.split(",")[1], 7).then(
              base64Info => {
                if (
                  base64Info &&
                  base64Info.split &&
                  base64Info.split(",")[1]
                ) {
                  this.b64LogoFooterContent = base64Info.split(",")[1];
                }
              }
            );
          }
        })
        .catch(() => {
          this.applyCompanyLogoLoading = false;
        });
    },
    onChangeImg() {
      if (this.validateLogos) {
        this.applyCompanyLogoButtonDisabled = false;
        this.previewImage();
        this.saveCompanyLogo();
      } else this.applyCompanyLogoButtonDisabled = true;
    },
    onChangeFooterImg() {
      if (this.validateLogos) {
        this.applyCompanyLogoButtonDisabled = false;
        this.previewFooterImage();
        this.saveCompanyFooterLogo();
      } else this.applyCompanyLogoButtonDisabled = true;
    },
    previewImage() {
      this.urlCustomLogo =
        this.customLogo && URL.createObjectURL(this.customLogo);
    },
    previewFooterImage() {
      this.urlCustomFooterLogo =
        this.customFooterLogo && URL.createObjectURL(this.customFooterLogo);
    },
    onResetMailTemplate() {
      this.computedSelectItem.template = this.defaultTemplateComputed();
    },
    onEditMailTemplates() {
      this.editMailTemplates = true;
      this.lastDefaultLanguage = this.computedSelectItem.language;
      this.customTemplate = AssignObj(DefaultMailTemplateData(), {
        ...this.computedSelectItem.template
      });
    },
    removeNotification(email) {
      this.notifications = this.notifications.filter(
        notification => notification.email !== email
      );
    },
    onClickCopyFlowTokenId(id) {
      navigator.clipboard.writeText(
        id ? id : this.computedSelectItem.flowTokenId
      );
      this.$toasted.global.toast_success({
        message: "Se ha copiado el identificador del flujo al portapapeles"
      });
    },

    duplicateFlow(item) {
      this.selectedItemDuplicate = item;
      this.dialogDuplicate = true;
    },
    duplicateItemConfirm() {
      const item = this.selectedItemDuplicate;
      this.isTableLoading = true;
      this.$store
        .dispatch("getFlow", item.flowTokenId)
        .then(data => {
          data.flowTokenId = undefined;
          data.name += "_copia";
          this.onFinalAction(void 0, data);
        })
        .catch(err => {
          console.log(err);
          this.$toasted.global.toast_error({
            message: "No se ha podido duplicar el flujo"
          });
        })
        .finally(() => {
          this.dialogDuplicate = false;
          this.isDuplicating = false;
        });
    },
    onFinalAction(e, item) {
      if (!item) this.finalActionLoading = true;

      this.computedSelectItem.corporateSignature = {
        type: this.corporateSignature.corporateSignatureType,
        corporateSignatureId: this.corporateSignature.corporateSignatureId
      };

      if (this.computedSelectItem.flowType === 1) {
        this.computedSelectItem.widget = {
          customText: "Documento Firmado",
          page: this.widget.page,
          x: Math.round(this.widget.x),
          y: Math.round(this.widget.y)
        };
        if (this.widget.scaleY && this.widget.scaleX) {
          this.computedSelectItem.widget.height = Math.round(
            this.widget.height * this.widget.scaleY
          );
          this.computedSelectItem.widget.width = Math.round(
            this.widget.width * this.widget.scaleX
          );
        } else {
          this.computedSelectItem.widget.height = Math.round(
            this.widget.height
          );
          this.computedSelectItem.widget.width = Math.round(this.widget.width);
        }
      }
      const outItem = Object.assign({}, item || this.computedSelectItem);
      if (outItem.authenticationType !== 20) outItem.accessCode = void 0;
      this.$store
        .dispatch(
          !!item || !this.computedSelectItem.flowTokenId
            ? "postFlow"
            : "putFlow",
          outItem,
          item ? null : this.computedSelectItem.flowTokenId
        )
        .then(() => {
          this.flowView = false;
          this.tab = null;
          this.resetSelectedItem();
          this.$toasted.global.toast_success({
            message: `Se ha ${
              item
                ? "duplicado"
                : !this.computedSelectItem.flowTokenId
                ? "creado"
                : "actualizado"
            } el flujo correctamente`
          });
          this.getItems();
        })
        .catch(err => {
          console.log(err);
          this.$toasted.global.toast_error({
            message: `No se ha podido ${
              item
                ? "duplicar"
                : !this.computedSelectItem.flowTokenId
                ? "crear"
                : "actualizar"
            } el flujo`
          });
        })
        .finally(() => {
          this.finalActionLoading = false;
        });
    },

    newItemClick() {
      this.$refs.fileInput.click();
    },

    loadFirstsItems() {
      if (this.tableOptions.page === 1) {
        this.getItems();
      } else {
        this.tableOptions.page = 1;
      }
    },

    loadLastsItems() {
      let page = Math.ceil(
        this.itemsTotalCount / this.tableOptions.itemsPerPage
      );
      if (this.tableOptions.page !== page) {
        this.tableOptions.page = page;
      } else {
        this.getItems();
      }
    },

    getItems() {
      let offset =
        this.tableOptions.itemsPerPage * (this.tableOptions.page - 1);
      let limit = this.tableOptions.itemsPerPage;

      this.isTableLoading = true;
      this.$store
        .dispatch("getFlows", {
          limit,
          offset
        })
        .then(data => {
          this.flows = data;
          this.getHistoricFlowsError = false;
        })
        .catch(err => {
          console.log(err);
          this.getHistoricFlowsError = true;
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    },
    /** END HISTORIC */
    removeItem(item) {
      this.selectedItemDelete = item;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.isDeleting = true;
      const item = this.selectedItemDelete;
      this.isTableLoading = true;
      this.$store
        .dispatch("deleteFlow", item.flowTokenId)
        .then(() => {
          this.flowView = false;
          this.tab = null;
          this.$toasted.global.toast_success({
            message: "Se ha borrado el flujo correctamente"
          });
          this.getItems();
        })
        .catch(err => {
          console.log(err);
          this.$toasted.global.toast_error({
            message: `No se ha podido borrar el flujo`
          });
        })
        .finally(() => {
          this.isDeleting = false;
          this.dialogDelete = false;
        });
    },

    /** DOCUMENT DETAILS */
    showFlowView(item, flowType) {
      this.$refs.form && this.$refs.form.reset();
      this.isDocumentRendered = false;
      this.widgetOn = false;
      setTimeout(() => {
        if (item) {
          this.loadingDetail = true;
          this.flowView = true;
          this.isTableLoading = true;
          this.$store
            .dispatch("getFlow", item.flowTokenId)
            .then(data => {
              if (data) {
                if (!data.accessCode) {
                  data.accessCode = { challenge: String(), format: String() };
                }

                if (!data.template) {
                  data.template = this.defaultTemplateComputed();
                } else if (
                  data.template.b64LogoContent ||
                  data.template.b64LogoFooterContent
                ) {
                  if (data.template.b64LogoContent) {
                    ScaleDataURL(
                      "data:image/jpg;base64," + data.template.b64LogoContent,
                      7
                    ).then(customData => {
                      this.customLogo = DataURLtoFile(customData, "");
                      this.previewImage();
                    });
                  }
                  if (data.template.b64LogoFooterContent) {
                    ScaleDataURL(
                      "data:image/jpg;base64," +
                        data.template.b64LogoFooterContent,
                      7
                    ).then(customData => {
                      this.customFooterLogo = DataURLtoFile(customData, "");
                      this.previewFooterImage();
                    });
                  }
                }
                if (data.corporateSignature) {
                  this.corporateSignature.corporateSignatureType =
                    data.corporateSignature.type;
                  this.corporateSignature.corporateSignatureId =
                    data.corporateSignature.corporateSignatureId;
                } else {
                  this.corporateSignature = {
                    corporateSignatureType: 0,
                    corporateSignatureId: null
                  };
                }
                if (data.actionType === 20) {
                  data.actionType = 21;
                }
                if (data.widget) {
                  this.widget = data.widget;
                }
                this.computedSelectItem = Object.assign(data, {});
                this.notifications =
                  this.computedSelectItem.notifications || [];
                if (data.dataFile) {
                  const file = this.base64ToDocFile(
                    data.dataFile.b64Content,
                    data.dataFile.name
                  );
                  this.fileInputWord = file;
                  this.onWordDocumentChanged(file);
                }
              } else this.resetSelectedItem();
              this.flowView = true;
              this.onChangeLanguage(data.language, false);
            })
            .catch(err => {
              console.log(err);
              this.flowView = false;
              this.$toasted.global.toast_error({
                message: `No se ha podido obtener los datos del flujo`
              });
            })
            .finally(() => {
              this.isTableLoading = false;
              this.loadingDetail = false;
            });
        } else {
          this.resetSelectedItem(false, flowType);
          this.flowView = true;
          this.onChangeLanguage(this.computedSelectItem.language, false);
        }
      }, 0);
    },
    base64ToDocFile(base64, filename) {
      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      });
      const file = new File([blob], filename, { type: blob.type });
      return file;
    },
    resetSelectedItem(defaultValue = false, flowType = 0) {
      this.urlCustomLogo = null;
      this.urlCustomFooterLogo = null;
      this.customLogo = null;
      this.customFooterLogo = null;
      this.corporateSignature = {};
      this.computedSelectItem = {
        flowTokenId: undefined,
        name: "flujo_" + new Date().toISOString(),
        language: 0,
        flowType: flowType,
        expirationDaysTimeout: 7,
        sendMethod: 1,
        senderName: String(),
        senderMail: String(),
        reminderDays: void 0,
        sendDocToRecipient: false,
        sendDocToSender: false,
        authenticationType: 0,
        actionType: 21,
        accessCode: { challenge: String(), format: String() },
        notifications: [],
        template: defaultValue
          ? DefaultMailTemplateData()
          : this.defaultTemplateComputed(),
        widget: null
      };
      this.widgetOn = false;
      this.selectedFile = null;
      this.fileInputWord = null;
      this.isDocumentRendered = false;
      this.widget = {
        recipientEmail: null,
        recipientName: null,
        documentId: 0,
        height: 0,
        width: 0,
        scaleX: 1,
        scaleY: 1,
        x: 0,
        y: 0,
        page: 1
      };
    },
    onDocumentErrored() {
      this.$router.push({ name: "RemoteSignaturesSent" });
      this.$toasted.global.toast_error({
        message: "No se ha podido cargar el PDF"
      });
    },
    onPageRendered(pageNumber) {
      if (!this.isDocumentRendered && pageNumber === 1) {
        this.isDocumentRendered = true;
        //widget previo, no invisible
        if (
          this.computedSelectItem.widget &&
          this.computedSelectItem.widget.height !== 0 &&
          this.computedSelectItem.widget.width !== 0
        ) {
          this.positionateWidgetIfExists(this.computedSelectItem.widget);
        }
      }
    },
    onUpdateCurrentPage(pageNumber) {
      this.currentDocumentPage = pageNumber;
    },
    positionateWidget() {
      bus.$emit("repositionate-recipient", {
        x: 0,
        y: 0,
        scaleX: 1,
        scaleY: 1,
        height: 76.5,
        width: 136.5,
        recipientEmail: "Firmante",
        recipientName: "Firmante",
        documentId: this.widget.documentId,
        pageNumber: this.currentDocumentPage,
        flow: true
      });
      this.widget.width = 136.5;
      this.widget.height = 76.5;
      this.widget.page = this.currentDocumentPage;
      this.widgetOn = true;
    },
    positionateWidgetIfExists(widget) {
      bus.$emit("repositionate-recipient", {
        x: widget.x,
        y: widget.y,
        scaleX: 1,
        scaleY: 1,
        height: widget.height,
        width: widget.width,
        recipientEmail: "Firmante",
        recipientName: "Firmante",
        documentId: widget.documentId,
        pageNumber: widget.page,
        exists: true
      });
      this.widget.width = widget.width;
      this.widget.height = widget.height;
      this.widget.page = widget.page;
      this.widgetOn = true;
    },
    removeWidget() {
      this.widgetOn = false;
      bus.$emit("remove-recipient", {
        recipientEmail: "Firmante",
        pageNumber: this.widget.page
      });
      this.widget = {
        recipientEmail: null,
        recipientName: null,
        documentId: 0,
        height: 0,
        width: 0,
        scaleX: 1,
        scaleY: 1,
        x: 0,
        y: 0,
        page: 1
      };
    },
    closeFlowDialog() {
      this.flowView = false;
      if (this.fileInputWord) {
        this.fileInputWord = null;
      }
    }
  }
};
</script>
<style scoped>
.tab-container {
  min-height: 600px;
  position: relative !important;
}
@media (max-width: 460px) {
  .btn--expanded {
    width: 95%;
  }
}
</style>
